import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import AllBlogs from "../components/routes/Blog/AllBlogs";
// import { WmkSeo } from "wmk-lib";
import { baseUrl, twitter } from "../vars/ThemeOptions";
import Layout from "../components/layout/MainLayout";
import { WmkSeo } from "wmk-seo";
import { BlogExcerptQuery } from "../fragments/NodeBlogFields";

const BlogPage = () => {
  const {
    blogs,
  }: { blogs: { edges: { node: BlogExcerptQuery }[] } } = useStaticQuery(query);
  //console.log(blogs);
  return (
    <Layout>
      <WmkSeo.Meta
        title={"Environmental, Social & Governance"}
        path={"/"}
        slug={"blog"}
        //description={description}
        ogImageUrl={""}
        twitterImageUrl={""}
        twitterHandle={twitter}
        siteTitle={"ESG Blog"}
        baseUrl={baseUrl}
      />
      <AllBlogs blogs={blogs.edges.map((b) => b.node)} />
    </Layout>
  );
};

export default BlogPage;

const query = graphql`
  {
    blogs: allContentfulBlogs {
      edges {
        node {
          ...NodeBlogExcerptFields
        }
      }
    }
  }
`;

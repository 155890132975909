import React from "react";
import { BlogExcerptQuery } from "../../../fragments/NodeBlogFields";
import ModernFeed from "./ModernFeed/ModernFeed";

const AllBlogs = ({ blogs }: { blogs: BlogExcerptQuery[] }) => {
  //console.log(blogs);
  return blogs && blogs.length ? <ModernFeed posts={blogs} /> : null;
};

export default AllBlogs;

import React from "react";
import { Row, Col, Card, Container } from "react-bootstrap";
import moment from "moment";
import styled from "styled-components";
// import { WmkLink } from "wmk-lib";
// import Img from "gatsby-image";
import BlogLink from "../BlogLink";
import { colors } from "../../../../vars/palette";
import { WmkLink } from "wmk-link";
import { BlogExcerptQuery } from "../../../../fragments/NodeBlogFields";
import { Img, WmkImage } from "wmk-image";

export const StyledCard = styled(Card)`
  padding: 2vh;
`;

const StyledBlogExcerptImg = styled(Col)`
  .gatsby-image-wrapper {
    width: 100% !important;
    img {
      max-width: 100%;
      width: 100%;
      height: 350px;
    }
    tranisition: all .3s ease;
    :hover {
      img {
        transform: scale(1.1)
        transition: all .3s ease;
      }      
    }
  }
`;

const StyledHeading = styled.h3`
  padding: 0 2rem;
  color: ${colors.hex("primary")};
  text-transform: uppercase;
  span {
    text-transform: none;
    color: #999999;
  }
`;

const ModernFeed = ({ posts }: { posts: BlogExcerptQuery[] }) => {
  return (
    <Container>
      <Row style={{ padding: "4vh", marginBottom: "2vh" }}>
        <StyledHeading className="raj">
          <span>esg-reports.com</span>
          {" // Blog"}
        </StyledHeading>
        {posts.map((post, i) => {
          //console.log(post);
          const { title, publishDate, heroImage, slug } = post;
          //const { node } = feat ? feat : { node: null };
          const date = moment(publishDate);
          const image = new Img(heroImage);
          // const { fixed } = heroImage ? heroImage : { fixed: null };
          return (
            <Col key={title + i} xs={12} sm={12} md={i === 0 ? 12 : 6}>
              <Row className="flex-column">
                <Container
                  style={{
                    borderBottom: "1px solid #ccc",
                    padding: "2vh",
                    //margin: "2vh",
                  }}
                >
                  <StyledBlogExcerptImg>
                    <WmkLink to={"/blog/" + slug}>
                      <WmkImage image={image} />
                      {/* <Img fixed={fixed} alt={title} /> */}
                    </WmkLink>
                  </StyledBlogExcerptImg>
                  <Col>
                    <BlogLink to={"/blog/" + slug}>{title}</BlogLink>
                  </Col>
                  <Col style={{ padding: "0 1.125rem" }}>
                    {date.format("MMM DD, YYYY")}
                  </Col>
                </Container>
              </Row>
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default ModernFeed;
